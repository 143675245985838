import * as THREE from 'three'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Model from './model'
import { OctahedronBufferGeometry } from 'three';
gsap.registerPlugin(ScrollTrigger);
/*------------------------------
Renderer
------------------------------*/
const renderer = new THREE.WebGLRenderer({
  antialias: true,
  alpha: true
});
renderer.setSize( window.innerWidth, window.innerHeight );
document.body.appendChild( renderer.domElement );


/*------------------------------
Scene & Camera
------------------------------*/
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera( 
  50, 
  window.innerWidth / window.innerHeight,
  0.1,
  100
);
camera.position.z = 5;
camera.position.y = 1;


/*------------------------------
Mesh
------------------------------*/
const geometry = new THREE.BoxGeometry(2, 2, 2);
const material = new THREE.MeshBasicMaterial( { 
  color: 0x00ff00,
} );
const cube = new THREE.Mesh( geometry, material );
// scene.add( cube );


/*------------------------------
OrbitControls
------------------------------*/
const controls = new OrbitControls( camera, renderer.domElement );
controls.enabled = false;


/*------------------------------
Helpers
------------------------------*/
// const gridHelper = new THREE.GridHelper( 10, 10 );
// scene.add( gridHelper );
// const axesHelper = new THREE.AxesHelper( 5 );
// scene.add( axesHelper );


/*------------------------------
Models
------------------------------*/

const pawn = new Model({
  name:'Pawn',
  file: './models/pawn.glb',
  scene: scene,
  color1:'red',
  color2:'yellow',
  background:'#47001b',
  placeOnLoad: true
})

const knight = new Model({
  name:'Knight',
  file: './models/knight.glb',
  scene: scene,
  color1:'blue',
  color2:'pink',
  background:'#110047'
})

const bishop = new Model({
  name:'Bishop',
  file: './models/bishop.glb',
  scene: scene,
  color1:'red',
  color2:'maroon',
  background:'#000080'
})

const rook = new Model({
  name:"Rook",
  file: './models/rook.glb',
  scene: scene,
  color1:'mediumturquoise',
  color2:'powderblue',
  background:'#191970'
})

const queen = new Model({
  name:"Queen",
  file: './models/queen.glb',
  scene: scene,
  color1:'lime',
  color2:'lightgreen',
  background:'#006400'
})

const king = new Model({
  name:"King",
  file: './models/king.glb',
  scene: scene,
  color1:'orangered',
  color2:'deeppink',
  background:'#000000'
})




/*------------------------------
Controllers
------------------------------*/
function first() {

  pawn.add()
  knight.remove()
  bishop.remove()
  rook.remove()
  queen.remove()
  king.remove()
}

function second() {

  pawn.remove()
  knight.add()
  bishop.remove()
  rook.remove()
  queen.remove()
  king.remove()
}

function third() {
  pawn.remove()
  knight.remove()
  bishop.add()
  rook.remove()
  queen.remove()
  king.remove()
}

function fourth() {
  pawn.remove()
  knight.remove()
  bishop.remove()
  rook.add()
  queen.remove()
  king.remove()
}

function five() {
  pawn.remove()
  knight.remove()
  bishop.remove()
  rook.remove()
  queen.add()
  king.remove()
}

function six() {
  pawn.remove()
  knight.remove()
  bishop.remove()
  rook.remove()
  queen.remove()
  king.add()
}


var target = document.querySelector(".speak");
let tl = gsap.timeline({
  scrollTrigger: {
  trigger: ".first",
  start: "top top",
  end:"bottom bottom",
  onEnter : () => first(),
  onEnterBack : () => first(),
  }

});

let tlsecond = gsap.timeline({
  scrollTrigger: {
  trigger: ".second",
  start: "top top",
  end:"bottom bottom",
  onEnter : () => second(),
  onEnterBack : () => second(),
  }
});

let tlthird = gsap.timeline({
  scrollTrigger: {
  trigger: ".third",
  start: "top top",
  end:"bottom bottom",
  onEnter : () => third(),
  onEnterBack : () => third(),
  }
});

let tlfourth= gsap.timeline({
  scrollTrigger: {
  trigger: ".fourth",
  start: "top top",
  end:"bottom bottom",
  onEnter : () => fourth(),
  onEnterBack : () => fourth(),
  }
});

let tlfive= gsap.timeline({
  scrollTrigger: {
  trigger: ".five",
  start: "top top",
  end:"bottom bottom",
  onEnter : () => five(),
  onEnterBack : () => five(),
  }
});

let tlsix= gsap.timeline({
  scrollTrigger: {
  trigger: ".six",
  start: "top top",
  end:"bottom bottom",
  onEnter : () => six(),
  onEnterBack : () => six(),
  }
});


/*------------------------------
Clock
------------------------------*/
const clock = new THREE.Clock()

/*------------------------------
Loop
------------------------------*/
const animate = function () {
  requestAnimationFrame( animate );
  renderer.render( scene, camera );

  if (pawn.isActive) {
  pawn.particlesMaterial.uniforms.uTime.value = clock.getElapsedTime()
}

if (knight.isActive) {
  knight.particlesMaterial.uniforms.uTime.value = clock.getElapsedTime()
}
if (bishop.isActive) {
  bishop.particlesMaterial.uniforms.uTime.value = clock.getElapsedTime()
}

if (rook.isActive) {
  rook.particlesMaterial.uniforms.uTime.value = clock.getElapsedTime()
}

if (queen.isActive) {
  queen.particlesMaterial.uniforms.uTime.value = clock.getElapsedTime()
}

if (king.isActive) {
  king.particlesMaterial.uniforms.uTime.value = clock.getElapsedTime()
}



};
animate();


/*------------------------------
Resize
------------------------------*/
function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize( window.innerWidth, window.innerHeight );
}
window.addEventListener( 'resize', onWindowResize, false );

/*------------------------------
MouseMove
------------------------------*/
function onMouseMove(e) {

  const x = e.clientX
  const y = e.clientY

  gsap.to(scene.rotation, {
    y: gsap.utils.mapRange(0, window.innerWidth, .25, -.25, x),
    x: gsap.utils.mapRange(0, window.innerHeight, .25, -.25, y)
  })

}
window.addEventListener('mousemove', onMouseMove)

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}